.sponsors-container {
    display: flex;
    flex-direction: column;
    padding: 150px 1vw 0px 1vw;
    min-width: 100vw;
}

.sponsor-header-box {
    margin-left: 8%;
    margin-bottom: 32px;
    padding: 8px 20px;
    border-bottom: 1px solid white;
    align-self: flex-start;
}

.sponsor-header {
    font-size: 40px;
}

.sponsor-info {
    font-size: 20px;
    color: rgba(255, 255, 255, 0.8);
}

.sponsor-zone {
    display: flex;
    flex-direction: column;
    gap: 32px;
    margin-bottom: 72px;
}

.sponsor-title {
    font-size: 30px;
    font-weight: 500;
    text-align: center;
    padding: 2px 8px;
    border-bottom: 2px solid white;
    align-self: center;
}

.sponsor-list {
    display: flex;
    justify-content: center;
    gap: 0.8vw;
    flex-wrap: wrap;
}

.sponsor-box {
    display: flex;
    flex-direction: column;
    width: 20%;
    min-width: 260px;
    aspect-ratio: 3/4;
    background-color: rgba(0, 0, 0, 0.4);
    padding: 0px;
    border-radius: 12px;
    border: 3px solid rgba(0, 0, 0, 0.6);
    overflow: hidden;
}

.sponsor-box.gold {
    border: 3px solid gold;
}

.sponsor-box.silver {
    border: 3px solid silver;
}

.sponsor-box.bronze {
    border: 3px solid rgb(155, 72, 13);
}

.sponsor-box-image-box {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 45%;
    padding: 20px;
}

.sponsor-box-image {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.sponsor-box-text {
    display: flex;
    flex-direction: column;
    height: 55%;
    padding: 0 6%;
}

.sponsor-box-name {
    font-size: 22px;
    margin-bottom: 4px;
    margin-left: 4px;
}

.sponsor-box-info {
    font-size: 16px;
    color: rgb(133, 133, 133);
}

@media only screen and (max-width: 1170px) {
    .sponsors-container {
        padding-top: 100px;
        padding-bottom: 20px;
    }

    .sponsor-header-box {
        margin: 0 4%;
        margin-bottom: 32px;
        padding: 8px 12px;
    }

    .sponsor-header {
        font-size: 28px;
        margin-bottom: 10px;
    }

    .sponsor-info {
        font-size: 18px;
    }

    .sponsor-list {
        gap: 3%;
    }

    .sponsor-box {
        margin-bottom: 20px;
        width: 47%;
        min-width: 90%;
        aspect-ratio: 1;
    }

    .sponsor-box-image-box {
        height: 54%;
    }

    .sponsor-box.gold {
        border-width: 1px;
    }

    .sponsor-box.silver {
        border-width: 1px;
    }

    .sponsor-box.bronze {
        border-width: 1px;
    }

    .sponsor-box-name {
        margin-bottom: 2px;
        margin-left: 8px;
    }
}
