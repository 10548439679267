.social-link-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  border: 3px solid black;
  aspect-ratio: 1;
  border-radius: 50%;
  cursor: pointer;
  max-width: 150px;
  max-height: 150px;
  position: relative;
  overflow: hidden;
  z-index: 1;
  transition: 300ms ease-in-out;
  transition-delay: 150ms;

  background-image: linear-gradient(
    to bottom left,
    var(--backgroundStart),
    var(--backgroundEnd)
  );
}

.link-zone:hover {
  transform: scale(1.05);
}

.shake {
  animation: shake-animation 1500ms ease-in-out;
}

@media only screen and (max-width: 1170px) {
}

@keyframes shake-animation {
  0% {
    transform: rotate(0deg);
  }
  10% {
    transform: rotate(7deg);
  }
  20% {
    transform: rotate(-4deg);
  }
  30% {
    transform: rotate(8deg);
  }
  40% {
    transform: rotate(-2deg);
  }
  50% {
    transform: rotate(5deg);
  }
  60% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
