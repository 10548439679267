.speakers-container {
    display: flex;
    flex-direction: column;
    padding: 150px 1vw 60px 1vw;
    min-width: 100vw;
}

.speakers-header-box {
    margin-left: 8%;
    margin-bottom: 28px;
    padding: 8px 20px;
    border-bottom: 1px solid white;
    align-self: flex-start;
}

.speakers-header {
    font-size: 40px;
}

.speakers-info {
    font-size: 20px;
    color: rgba(255, 255, 255, 0.8);
}

.speakers-list {
    display: flex;
    justify-content: center;
    gap: 0.8vw;
    flex-wrap: wrap;
}

.speaker-box {
    display: flex;
    flex-direction: column;
    width: 14%;
    min-width: 200px;
    aspect-ratio: 3/4;
    background-color: rgba(0, 0, 0, 0.4);
    padding: 0px;
    border-radius: 12px;
    border: 1px solid rgba(0, 0, 0, 0.6);
}

.speaker-box-image-box {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 65%;
}

.speaker-box-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.speaker-box-text {
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: space-evenly;
    height: 35%;
    padding: 3px 6%;
}

.speaker-box-name {
    font-size: 1.3em;
    margin-bottom: 4px;
}

.speaker-box-name-extended {
    position: absolute;
    visibility: hidden;
    font-size: 1.3em;
}

.speaker-box-text-info {
    display: flex;
    position: relative;
    flex-direction: column;
    gap: 2px;
}

.speaker-box-subject {
    font-size: 0.9em;
    color: rgb(133, 133, 133);
}

.speaker-box-subject-extended {
    position: absolute;
    visibility: hidden;
}

.speaker-box-company {
    font-size: 0.9em;
    color: rgb(133, 133, 133);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.speaker-box-company-extended {
    position: absolute;
    visibility: hidden;
    white-space: nowrap;
}

.speaker-box-name-extended.hovered {
    visibility: visible;
    background-color: rgba(0, 0, 0, 0.9);
    padding: 6px 10px;
    z-index: 3;
    border: 2px solid rgb(54, 54, 54);
    border-radius: 4px;
}

.speaker-box-subject-extended.hovered {
    visibility: visible;
    background-color: rgba(0, 0, 0, 0.9);
    padding: 6px 10px;
    z-index: 3;
    border: 2px solid rgb(54, 54, 54);
    border-radius: 4px;
}

.speaker-box-company-extended.hovered {
    visibility: visible;
    background-color: rgba(0, 0, 0, 0.9);
    padding: 6px 10px;
    z-index: 3;
    border: 2px solid rgb(54, 54, 54);
    border-radius: 4px;
}

@media only screen and (max-width: 1170px) {
    .speakers-container {
        padding-top: 100px;
    }

    .speakers-header-box {
        margin: 0 4%;
        margin-bottom: 28px;
        padding: 8px 12px;
    }

    .speakers-header {
        font-size: 28px;
        margin-bottom: 10px;
        line-height: 100%;
    }

    .speakers-info {
        font-size: 18px;
    }

    .speakers-list {
        gap: 4%;
    }

    .speaker-box {
        margin-bottom: 12px;
        width: 44%;
        min-width: 0;
    }

    .speaker-box-image-box {
        height: 64%;
    }

    .speaker-box-name {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .speaker-box-subject {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .speaker-box-company-extended {
        white-space: normal;
        width: 100%;
    }
}
