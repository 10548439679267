.geekday-container {
    min-height: 100vh;
    width: 100vw;
    background-color: rgba(0, 0, 0, 0.5);
    overflow: hidden;
}

.geekday-tab-container {
    display: flex;
    flex-wrap: nowrap;
    height: auto;
    align-items: flex-start;

    transition: all 500ms ease-out;
}

.geekday-scroll-top-button {
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    outline: none;
    position: fixed;
    bottom: 60px;
    right: 80px;
    border-radius: 50%;
    width: 72px;
    aspect-ratio: 1;
    background-color: var(--secondaryColor);
    border: 4px solid var(--primaryColor);
    cursor: pointer;
    z-index: 3;

    visibility: hidden;
    opacity: 0;

    transition: all 500ms ease-in-out;
}

.geekday-scroll-top-button.show {
    visibility: visible;
    opacity: 0.8;
}

.geekday-navbar-ticket {
    position: absolute;
    right: 6%;
    background-color: var(--secondaryColor);
    padding: 12px 18px;
    border-radius: 4px;
    font-size: 18px;
}

@media only screen and (max-width: 1170px) {
    .geekday-scroll-top-button {
        bottom: 30px;
        right: 40px;
        width: 56px;
    }

    .geekday-navbar-ticket {
        font-size: 16px;
        position: relative;
        right: 0;
        margin-top: 0px;
    }
}
