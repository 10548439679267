.footer-container {
  height: 100px;
  background-color: var(--darkFadedBlack);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 13%;
}

.footer-zone {
  display: flex;
  justify-content: center;
}

.footer-zone.left {
  height: 75%;
  border: none;
  background-color: transparent;
  cursor: pointer;
}

.footer-logo {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.footer-zone.right {
  flex-direction: column;
  align-items: flex-end;
  gap: 5px;
}

@media only screen and (max-width: 1170px) {
  .footer-container {
    height: 80px;
    padding: 0 6%;
  }

  .footer-zone {
    gap: 3px;
  }

  .footer-zone.left {
    height: 100%;
  }
  .footer-logo {
    transform: scale(0.8) translateX(-15%);
  }
}
