.navbar-container {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 90px;
    width: 100%;
    padding: 30px 100px 0 120px;
    z-index: 10;

    transition: all 300ms ease-in-out;
}

.navbar-container.scrolled {
    background-color: var(--primaryColorHalf);
    padding: 10px 100px 0 120px;
    box-shadow: 0 0 20px var(--darkFadedBlack);

    backdrop-filter: blur(10px);
}

.navbar-zone {
    display: flex;
    align-items: center;
    justify-content: center;
}

.navbar-zone.left {
    align-items: center;
    justify-content: center;
    width: 6%;
    margin-bottom: 10px;
    background-color: transparent;
    border: none;
    cursor: pointer;
}

.navbar-logo {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.navbar-zone.right {
    gap: 32px;
}

.navbar-button {
    background-color: transparent;
    border: none;
    color: var(--fontColor);
    font-size: 18px;
    cursor: pointer;

    transition: all 200ms ease-in-out;
}

.navbar-button.hovered {
    scale: 1.04;
}

.navbar-button.not-hovered {
    opacity: 0.69;
}

.navbar-special {
    background-color: var(--secondaryColor);
    padding: 12px 18px;
    border-radius: 4px;
    font-size: 18px;
}

@media only screen and (max-width: 1170px) {
    .navbar-container {
        height: 80px;
        padding: 30px 30px 0 30px;
    }

    .navbar-container.scrolled {
        padding: 5px 30px 0 30px;
    }

    .navbar-burger-button {
        background-color: transparent;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        border: none;
        z-index: 99;
        gap: 3px;
        padding: 7px;
        aspect-ratio: 1;
    }

    .navbar-zone.left {
        width: 20%;
        margin-bottom: 0;
    }

    .navbar-zone.right {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        flex-direction: column;
        gap: 15px;
        align-items: center;
        background-color: var(--darkFadedBlack);
        padding: 60px 0 30px 0;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        backdrop-filter: blur(10px);

        transition: all 400ms ease-in-out;
    }

    .navbar-zone.right.hidden {
        transform: translateY(-100%);
    }

    .navbar-zone.right.shown {
        transform: translateY(0);
    }

    .navbar-sitck {
        width: 22px;
        height: 4px;

        opacity: 1;
        background-color: var(--fontColor);
        transform: rotate(0);
        transition: all 500ms ease-in-out;
    }

    .navbar-sitck.top {
        transform: rotate(-45deg) translate(-25%, 120%);
    }

    .navbar-sitck.bottom {
        transform: rotate(45deg) translate(-22%, -100%);
    }

    .navbar-sitck.middle {
        opacity: 0;
    }
}
